.ant-layout-header {
  padding: 0 50px 0 10px;
}
.capitalize {
  text-transform: capitalize !important;
}
.ant-table-cell .anticon {
  font-size: 22px;
}
.ant-tag {
  border-radius: 18px;
  padding: 4px 8px;
}
.ant-tag-green {
  background: #c4efdf;
  color: #158957;
}
.ant-tag-red {
  background: #fcc5ce;
  color: #ad283d;
}
.ant-tag-blue {
  background: #ddefff;
  color: #0059ac;
}
.ant-tag-yellow {
  background: #fff0db;
  color: #e08304;
}
.ant-input,
.ant-select,
.ant-input-number-affix-wrapper,
.ant-input-group {
  /* White */
  background: #ffffff;
  /* Gray/300 */
  border: 1px solid #ced6de;
  box-shadow: 0px 1px 2px rgba(63, 63, 68, 0.1);
  border-radius: 4px;
}
.ant-input-lg,
.ant-select-lg {
  min-height: 48px !important;
}
.ant-select-selector,
.ant-select-selection-item {
  min-height: 40px !important;
  line-height: 40px !important;
}
.ant-form .ant-select-selector,
.ant-form .ant-select-selection-item {
  min-height: 48px !important;
  line-height: 48px !important;
}
.ant-input-search {
  min-height: 40px !important;
}
.ant-input-search .ant-input-group {
  min-height: 40px !important;
  line-height: 40px !important;
}
.ant-input-search .ant-input-group .ant-input-affix-wrapper {
  border: none;
  box-shadow: none;
}
.ant-input-search .ant-input-group .ant-input-affix-wrapper .ant-input {
  box-shadow: none;
}
.ant-input-search .ant-input-group .ant-input-group-addon {
  border: none;
  background: none;
  box-shadow: none;
}
.ant-input-search .ant-input-group .ant-input-group-addon .ant-input-search-button {
  border: none;
  box-shadow: none;
}
.ant-btn-primary {
  /* Blue/500 */
  background: #0d7fe9;
  border: 1px solid rgba(33, 41, 52, 0.07);
  /* Button Shadow */
  box-shadow: 0px 1px 0px rgba(27, 31, 35, 0.05);
  border-radius: 6px;
}
.ant-btn-secondary {
  /* Gray/100 */
  background: #f5f8fa;
  /* Gray/200 */
  border: 1px solid #e1e7ec;
  /* Button Shadow */
  box-shadow: 0px 1px 0px rgba(27, 31, 35, 0.05);
  border-radius: 6px;
}
.ant-radio-button-wrapper {
  border-radius: 4px !important;
  height: 48px !important;
  width: 135px !important;
}
.ant-radio-button-wrapper .alignCenter {
  margin: 5px 0px !important;
}
.ant-descriptions-item-container {
  display: flex;
  align-items: baseline;
}
.grayTag {
  background: #e1e7ec;
  color: #212934;
}
.backBtn a {
  color: #7c8db5;
}
.backBtn span {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #7c8db5;
}
.capitalize {
  text-transform: capitalize;
}
.pagination {
  float: right;
  margin-top: 30px;
  margin-bottom: 30px;
}
.ant-layout-content {
  min-height: 85vh;
}
.icon-box {
  width: 48px;
  height: 48px;
  text-align: center;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  /* opacity: 0.2; */
}
.icon-box img {
  width: 25px;
}
.mb-5 {
  margin-bottom: 5px;
}
.mb-10 {
  margin-bottom: 10px;
}
.mt-5 {
  margin-top: 5px;
}
.mt-10 {
  margin-top: 10px;
}
.mt-15 {
  margin-top: 15px;
}
.red {
  color: red;
}
.green {
  color: green;
}
@media (min-width: 1600px) and (max-width: 1720px) {
  .ant-statistic-content-value {
    font-size: 24px;
  }
  .icon-box {
    width: 48px;
    height: 48px;
  }
  .ant-table-container .ant-table-thead th,
  .tripsTableContainer .ant-table-thead th {
    font-size: 14px;
  }
  .ant-table-container .ant-table-tbody tr td,
  .tripsTableContainer .ant-table-tbody tr td {
    font-size: 14px;
  }
}
@media (min-width: 1366px) and (max-width: 1600px) {
  .ant-statistic-content-value {
    font-size: 18px;
  }
  .icon-box {
    width: 40px;
    height: 40px;
  }
  .ant-table-container .ant-table-thead th,
  .tripsTableContainer .ant-table-thead th {
    font-size: 12px;
  }
  .ant-table-container .ant-table-tbody tr td,
  .tripsTableContainer .ant-table-tbody tr td {
    font-size: 12px;
  }
}
@media (min-width: 1280px) and (max-width: 1366px) {
  .ant-statistic-content-value {
    font-size: 16px;
  }
  .icon-box {
    width: 32px;
    height: 32px;
  }
  .ant-table-container .ant-table-thead th,
  .tripsTableContainer .ant-table-thead th {
    font-size: 12px;
  }
  .ant-table-container .ant-table-tbody tr td,
  .tripsTableContainer .ant-table-tbody tr td {
    font-size: 12px;
  }
}
@media (min-width: 1024px) and (max-width: 1280px) {
  .ant-statistic-content-value {
    font-size: 14px;
  }
  .icon-box {
    width: 18px;
    height: 18px;
  }
  .tripsTableContainer .ant-table-thead th {
    font-size: 10px;
  }
  .tripsTableContainer .ant-table-tbody tr td {
    font-size: 10px;
  }
}
@media screen and (max-width: 1024px) {
  .ant-statistic-content-value {
    font-size: 12px;
  }
  .icon-box {
    width: 10px;
    height: 10px;
  }
  .ant-table-container .ant-table-thead th,
  .tripsTableContainer .ant-table-thead th {
    font-size: 8px;
  }
  .ant-table-container .ant-table-tbody tr td,
  .tripsTableContainer .ant-table-tbody tr td {
    font-size: 8px;
  }
}
#MainLayout {
  padding: 0 1rem;
  background-color: #FCFCFE;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.header {
  background-color: #FCFCFE !important;
}
#help-btn {
  padding-left: 24px;
}
#help-btn span:nth-of-type(2) {
  padding-left: 1rem;
  margin-left: 0;
}
#help-btn:hover,
#help-btn:focus {
  background-color: #FCFCFE;
  color: #1890ff;
}
#contentLayout {
  min-height: 85vh;
  width: 80%;
}
#contentLayout section.ant-layout {
  padding: 10px 0 !important;
  background-color: #FCFCFE;
  min-height: 100% !important;
}
#contentLayout section.ant-layout .ant-layout-content {
  overflow-y: hidden;
}
#contentLayout section.ant-layout #SideBarLayout {
  background-color: #FCFCFE;
  min-height: 100%;
  display: flex;
}
#contentLayout section.ant-layout #SideBarLayout div.ant-layout-sider-children {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-start;
}
.ant-table-content {
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.04);
  border-radius: 10px;
}
