body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif; */
  font-family: 'Hero New Regular';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #e5e5e5;
  word-spacing: -3px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.page-title {
  font-size: 1.5rem;
  font-weight: bold;
}

.ant-card-bordered {
  background: #ffffff;
  /* backoffice */

  box-shadow: 0px 2px 6px rgba(174, 174, 192, 0.1);
  border-radius: 10px;
}

.select-option-label-item {
  margin-right: 6px;
}

.input-box {
  display: flex;
  flex-direction: column;
}

.input-box label {
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
}

.rdw-editor-main {
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.02);
  border: 1px solid #e8e8e8;
  min-height: 200px;
  padding: 10px;
  margin-bottom: 20px;
}

.message-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 20px;

  background: #0d7fe9;
  border-radius: 6px;
}

.input {
  border-radius: 4px;
}

.primary-btn {
  background-color: #0d7fe9;
  padding: 10px 20px;
  color: #fff;
  border-radius: 4px;

  margin-left: auto;
}
